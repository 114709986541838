import { WalletCategory } from "@/shared/enums/wallets";
import { IWallet } from "@/shared/types/wallets";
import { AxiosResponse } from "axios";
import { DefineComponent } from "vue";

export interface quickLink {
  icon: string | any;
  two_tone_icon: string | any;
  title: string;
  sub_title: string;
  name: string;
}

export interface CryptoNetwork {
  name: string;
}

export enum quickLinksType {
  CRYPTO = "CRYPTO",
  FIAT = "FIAT",
}

export type QuickLinksStoreOptions = {
  selectedNetwork: CryptoNetwork | null;
  allCrypto: IWallet[];
  userAccValidation: {
    type: "email" | "phone";
    message: string;
  } | null;
  allFiatCoin: IWallet[];
  quickLinkType: WalletCategory | string;
  deposit: {
    step: number;
    coins: IWallet[];
    fiat: {
      amount: number;
      to: string;
      transactionFee: string;
      accountNumber: string;
      totalPayable: string;
      wallet: {
        name: string;
        image: string;
      };
      date: string;
    };
    selectedWallet: IWallet | null;
    walletAddress: string | null;
  };
  withdraw: {
    coins: IWallet[];
    step: number;
    selectedWallet: IWallet;
    amount: number;
    walletAddress: string | null;
    verification: {
      email?: boolean;
      mobile?: boolean;
    };
    fiat: {
      amount: string;
      from: string;
      to: string;
      bank: {
        name: string;
        image: string;
      };
      accountNumber: string;
      date: string;
    };
  };
  trade: {
    coins: IWallet[];
    activeTab: number;
    payCoin: IWallet | null;
    sell: {
      payCoin: IWallet | null;
      receiveWallet: IWallet | null;
      payAmount: number | null;
      receiveAmount: number | null;
    };
    buy: {
      payCoin: IWallet | null;
      receiveWallet: IWallet | null;
      payAmount: number | null;
      receiveAmount: number | null;
    };
    step: number;
    swap: {
      payAmount: number;
      receiveAmount: number;
      payCoin: IWallet | null;
      receiveWallet: IWallet | null;
    };
  };
};

export enum Tradetype {
  BUY = "BUY",
  SELL = "SELL",
}

export enum WithdrawStatusOptions {
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}

export interface IBeneficiary {
  id: number;
  bank: {
    bankName: string;
    accountName: string;
    image: string;
    accountNumber: string;
  };
}

export interface IAxiosApiResponse<T = any> extends AxiosResponse {
  data: {
    pagination?: any;
    status: number;
    data: T;
    message: string;
  };
}

export interface ILoginResponse {
  businessFeatures?: string[];
  session: {
    token: string;
    expiresAt: string;
  };
}

export interface IProfileResponse {
  profile: {
    id: string;
    email: string | null;
    phone: string | null;
    username: string;
    firstName: string | null;
    lastName: string | null;
    passwordUpdatedAt: string | null;
    profilePercentage: number;
    createdAt: string;
  };
  securityStatus: {
    isEmailVerificationActive: true;
    isGoogle2FaActive: false;
    isPhoneVerificationActive: false;
  };
}

export type CustomErrorResponse = {
  message: string;
  errors?: any;
};

export interface ICryptoWithdrawForm {
  amount: number | string;
  address: string;
  memo: string;
  network: string;
  asset: string;
}

export interface ICryptoWithdrawSummary {
  amount: number;
  address: string;
  network: string;
  asset: string;
  sessionRef: string;
  fee: number;
  security: ISecurityOptions;
  warnings: [];
  otp?: string;
}

export interface IResetPasswordForm {
  channel: string;
  identifier: string;
  newPassword: string;
  verificationToken: string;
}

export interface ISignUpForm {
  identifier: string;
  channel: string;
  password: string;
  validationToken: string;
}

export interface IResetTokenForm {
  identifier: string;
  gRecaptchaResponse: string;
  phoneChannel?: string;
}

export interface ISwapStoreData {
  allowedDestinationAssets: {
    all: string[];
    buy: string[];
    sell: string[];
  };
  allowedSourceAssets: {
    all: string[];
    buy: string[];
    sell: string[];
  };
  from: string;
  minimumAmount: string;
  offer: number;
  rate: {
    usdRate: number;
    fiatAsset: IAssetInfo;
  };
  referencePrices: {};
  to: string;
}

export interface IWithdrawalNetwork {
  isAvailable: boolean;
  network: string;
  withdrawalFee: number;
  maximumAllowedWithdrawalValue: number;
  minimumAllowedWithdrawalValue: number;
  requiresMemo: boolean;
}

export interface ICryptoAssetInfo extends IAssetInfo {
  address: string;
  memo: string;
  network: string;
  asset: string;
  id: string;
  subUnitName: string;
  totalReceived: number;
}

export interface ICryptoBusinessDepositWalletAssetInfo extends IAssetInfo {
  asset: {
    id: string;
    name: string;
    category: string;
    code: string;
    symbol: string;
    imageUrl: string;
    decimalPlaces: 9;
    subUnitName: string;
  };
  balance: 0;
}

export interface IBusinessDepositAddressInfoDetail {
  address: string;
  addressLegacy: string;
  memo: null;
  label: string;
  customerRef: string;
  createdAt: string;
  updatedAt: string;
  currencies: IBusinessDepositAddressInfoCurrencyInfo[];
}

export interface IBusinessDepositAddressInfoCurrencyInfo {
  asset: {
    name: string;
    category: string;
    code: string;
    symbol: string;
    imageUrl: string;
    decimalPlaces: number;
    subUnitName: string;
  };
  network: {
    network: string;
    asset: {
      asset: string;
      name: string;
      symbol: string;
      imageUrl: string;
      subUnitName: string;
      feeUnit: string;
    };
  };
  totalReceived: number;
  totalReceivedString: string;
}

export interface IBusinessDepositAddress {
  network: string;
  address: string;
  addressLegacy: string;
  memo: string;
  label: string;
  customerRef: string;
  currencies: IBusinessDepositAddressCurrency[];
  createdAt: string;
  updatedAt: string;
}

export interface IBusinessDepositAddressCurrency {
  asset: string;
  network: string;
  totalReceived: number;
}

export interface IBusinessDepositTransaction {
  amount: number;
  asset: ICryptoAssetInfo;
  assetInfo: ICryptoAssetInfo;
  confirmations: number;
  id: string;
  status: string;
  transactionID: string;
  usd: number;
  usdRate: number;
  userID: string;
  walletID: string;
  imgUrl: string;
  explorerUrl: string;
  address: string;
  addressLabel: string;
  createdAt: string;
  memo: string;
  network: string;
  updatedAt: string;
}

export interface IBusinessDepositCollect {
  id: string;
  asset: string;
  network: string;
  address: string;
  memo: string;
  amount: number;
  feeRate: number;
  status: string;
  remarks: string | null;
  transactionsCount: number;
  fueling: string | null;
  transactions: null;
  createdAt: string;
  updatedAt: string;
}

export interface IBusinessDepositGasStation {
  asset: {
    name: string;
    category: string;
    code: string;
    symbol: string;
    imageUrl: string;
    decimalPlaces: number;
    subUnitName: string;
  };
  network: {
    network: string;
    asset: {
      asset: string;
      name: string;
      symbol: string;
      imageUrl: string;
      subUnitName: string;
      feeUnit: string;
    };
  };
  wallet: IBusinessDepositCryptoDepositGasStationWallet;
  supports: IBusinessDepositGasStationSupportedAsset[];
}

export interface IBusinessDepositGasStationSupportedAsset {
  asset: {
    name: string;
    category: string;
    code: string;
    symbol: string;
    imageUrl: string;
    decimalPlaces: number;
    subUnitName: string;
  };
  network: IBusinessDepositGasStationNetworkAsset;
  amount: number;
}

export interface IBusinessDepositGasStationNetworkAsset {
  network: string;
  asset: {
    asset: string;
    name: string;
    symbol: string;
    imageUrl: string;
    subUnitName: string;
    feeUnit: string;
  };
}

export interface IBusinessDepositCryptoDepositGasStationWallet {
  network: string;
  address: string;
  addressLegacy: string;
  label: string;
  memo: string;
  balance: number;
}

export interface IBusinessDepositWebhookLog {
  address: string;
  addressLabel: string;
  businessCryptoDepositId: string;
  createdAt: string;
  hash: string;
  id: string;
  latestActivityAt: string;
  remark: string;
  requestPayload: string;
  responsePayload: string;
  retries: number;
  status: string;
  statusCode: string;
  url: string;
  userId: string;
}

export interface IBusinessDepositTransactionNetwork {
  addressExplorerUrlFmt: string;
  addressValidationRegEx: string;
  asset: string;
  assetPolicies: string;
  category: string;
  createdAt: string;
  decimalPlaces: number;
  defaultSwapPairs: string;
  defaultSwapPairsMargin: number;
  id: string;
  imageURL: string;
  name: string;
  network: string;
  networkPolicies: string;
  structure: string;
  subUnitDivisor: number;
  subUnitName: string;
  symbol: string;
  transactionExplorerUrlFmt: string;
  updatedAt: string;
}

export interface IBusinessDepositWallet {
  assets: ICryptoBusinessDepositWalletAssetInfo[];
  network: {
    asset: string;
    feeUnit: string;
    imageUrl: string;
    name: string;
    subUnitName: string;
    symbol: string;
  };
  balance: number;
}

export interface IAvailableCrypto extends IWallet {
  networks: IWithdrawalNetwork[];
}

export enum ValidationRequiredCode {
  EMAIL_VERIFICATION_REQUIRED = "EMAIL_VERIFICATION_REQUIRED",
  PHONE_VERIFICATION_REQUIRED = "PHONE_VERIFICATION_REQUIRED",
  KYC_REQUIRED = "KYC_REQUIRED",
}

export enum CONFIRM_EMAIL_STATUS {
  PENDING = "pending",
  CONFIRMED = "confirmed",
}

export interface IExchangeRate {
  asset: string;
  margin: number;
  buyRate: number;
  sellRate: number;
}

export interface IExchangeRateApiResponse {
  exchangeRates: any;
  nativeRates: {
    ngnUsd: number;
    rates: IExchangeRate[];
  };
}

export interface IExchangeRateSwap {
  amount: number;
  offer: number;
}

export interface IExchangeRateSwapData {
  amount: number;
  sourceAsset: string;
  destinationAsset: string;
}

export enum KYC_STATUS {
  PENDING = "PENDING",
  VERIFIED = "VERIFIED",
  REJECTED = "REJECTED",
  DUPLICATE = "DUPLICATE",
  REVIEW_REQUIRED = "REVIEW-REQUIRED",
}

export interface IAssetInfo {
  name: string;
  category: string;
  code: string;
  symbol: string;
  imageUrl: string;
  decimalPlaces: number;
}

export interface ISwapWallet {
  code: string;
  name: string;
  symbol: string;
  imageUrl: string;
  decimalPlaces: number;
  balance: number;
  balanceString: string;
  altBalanceAsset: string;
  altBalance: number;
  altBalanceString: string;
  totalBalance: number;
  totalBalanceString: string;
  altTotalBalance: number;
  altTotalBalanceString: string;
  hasPendingSwap: boolean;
  validPairs: Omit<ISwapWallet, "validPairs">[];
}

export interface ISwapInitResponse {
  allowedAssets: {
    all: ISwapWallet[];
    buy: ISwapWallet[];
    sell: ISwapWallet[];
  };
  referencePrices: {
    baseAsset: IAssetInfo;
    rates: {
      asset: IAssetInfo;
      value: number;
    }[];
  };
}

export interface ISwapSummaryDetails {
  readonly amount: number;
  destination: {
    asset: IAssetInfo;
    balance: number;
    balanceAfter: number;
  };
  id: string;
  readonly offer: number;
  rate: {
    readonly usdRate: number;
    fiatAsset: {
      name: string;
      category: string;
      code: string;
      symbol: string;
      imageUrl: string;
      decimalPlaces: number;
    };
  };
  readonly sessionRef: string;
  source: {
    asset: {
      name: string;
      category: string;
      code: string;
      symbol: string;
      imageUrl: string;
      decimalPlaces: number;
    };
    readonly balance: number;
    readonly balanceAfter: number;
  };
}

export interface IConfirmPayload {
  amount: number;
  sessionRef: string;
}

export enum ITradeAction {
  SELL = "sell",
  BUY = "buy",
  SWAP = "swap",
}

export interface ITradeActionTab {
  title: ITradeAction;
  description: string;
  color: string;
  icon: DefineComponent;
}

export interface IFiatWithdrawalType {
  name: string;
  id: number;
}

export enum VerificationChannel {
  WHATSAPP = "whatsapp",
  SMS = "sms",
  EMAIL = "email",
}

export interface ISecurityOptions {
  emailOtp: boolean | string;
  google2FaToken?: boolean | string;
  phoneOtp: boolean | string;
  google2fa?: string;
  data?: {
    otp: {
      emailOtp: boolean;
      phoneOtp: boolean;
      google2FaToken: boolean;
    };
    passkey: {
      isActive: boolean;
    };
    preferred: string;
  };
  showPassKey?: boolean;
}

interface WebAuthnCredentialResponse {
  attestationObject: string;
  authenticatorData: string;
  clientDataJSON: string;
  publicKey: string;
  publicKeyAlgorithm: number;
  transports: string[];
}

export interface IPasskey {
  attachment: string;
  createdAt: string;
  updatedAt: string;
  id: string;
  dataFormat: string;
  authenticatorDevice: {
    iconDark: string;
    iconLight: string;
    id: string;
    name: string;
    parsedId: string;
    webAuthnCredentials: any;
  };
  deviceAAUUID: string;
  type: string;
  userId: string;
  deviceName: string;
}

export enum SuccessTypes {
  AIRTIME = "airtime-success",
  BTU_SUCCESS = "btu-success",
  BUY_GIFTCARD = "buy-giftcard",
  CARD_FUND_SUCCESS = "card-fund-success",
  CARD_PENDING = "card-pending",
  CARD_READY = "card-ready",
  DEPOSIT = "deposit",
  UTILITY = "utility",
  SWAP = "swap",
  CABLE_TV = "cable-tv",
  SUBSCRIPTION = "subscription-success",
  TRANSFER = "transfer",
  WITHDRAWAL = "withdrawal",
}

export interface IVerificationFlowsData {
  aipriseData: {
    environment: string;
    templateId: string;
  };
  metamapData: [
    {
      flowId: string;
      clientId: string;
      label: string;
      provider: string;
      isActive: true;
    },
    {
      flowId: string;
      clientId: string;
      label: string;
      provider: string;
      isActive: true;
    }
  ];
  provider: string;
  verificationFlows: [
    {
      flowId: string;
      clientId: string;
      label: string;
      provider: string;
      isActive: true;
    },
    {
      flowId: string;
      clientId: string;
      label: string;
      provider: string;
      isActive: true;
    }
  ];
}

export enum BankTransferDepositSteps {
  SELECT_WALLET = 1,
  SELECT_BENEFICIARY = 2,
  INIT = 3,
  CONFIRM = 4,
  PEER = 5,
  SUCCESS = 6,
}

export enum MobileMoneyDepositSteps {
  SELECT_WALLET = 1,
  SELECT_BENEFICIARY = 2,
  FORM = 3,
  CONFIRM = 4,
  SUCCESS = 5,
}
