export default function useZendesk() {
  return {
    install: (app: any) => {
      const windowObject = window;
      const documentObject = document;
      const mountCallback = function() {
        const scriptElement = documentObject.createElement("script");
        scriptElement.type = "text/javascript";
        scriptElement.id = "ze-snippet";
        scriptElement.async = true;
        scriptElement.src = `https://static.zdassets.com/ekr/snippet.js?key=${import.meta.env.VITE_APP_ZENDESK_KEY}`;
        const firstScript = documentObject.getElementsByTagName("script")[0];
        if (firstScript.parentNode) {
          firstScript.parentNode.insertBefore(scriptElement, firstScript);
          scriptElement.onload = function() {
            window.zE("messenger", "hide");

            window.zE("messenger:on", "open", function() {
              window.zE("messenger", "show");
            });

            window.zE("messenger:on", "close", function() {
              window.zE("messenger", "hide");
            });
          };
        }
      };
      if (documentObject.readyState === "complete") {
        mountCallback();
      } else {
        windowObject.addEventListener("load", mountCallback, false);
      }
    },
  };
};