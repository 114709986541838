import AuthService from "@/services/auth.service";
import { IAxiosApiResponse, ILoginResponse, IResetPasswordForm, IResetTokenForm, ISignUpForm } from "@/shared/types";
import { IWallet } from "@/shared/types/wallets";
import { clearCookie } from "@/utils";
import { CHANNELS, objectsToCamelCaseObjects } from "@/utils/consts";
import IUserLoginModel from "@/utils/types/userLoginModel";
import { AxiosError, AxiosResponse } from "axios";
import { defineStore } from "pinia";
import { useCookies } from "vue3-cookies";

export const useUserStore = defineStore("user", {
  state: () =>
    <IUserLoginModel>{
      user: {
        email: "",
        firstName: "",
        id: "",
        lastName: "",
        passwordUpdatedAt: "",
        phone: "",
        username: "",
        fullName: "",
        webhookUrl: "",
      },
      limits: {
        currency: "",
        noKycLimits: {
          airtimeTopUp: "",
          cryptoDeposit: "",
          cryptoWithdrawal: "",
          fiatDeposit: "",
          fiatWithdrawal: "",
          giftCardBuy: "",
          giftCardSell: "",
          subscriptionPurchase: "",
          swap: "",
          transfer: "",
          utilityBillPayment: "",
        },
        kycLimits: {
          airtimeTopUp: "",
          cryptoDeposit: "",
          cryptoWithdrawal: "",
          fiatDeposit: "",
          fiatWithdrawal: "",
          giftCardBuy: "",
          giftCardSell: "",
          subscriptionPurchase: "",
          swap: "",
          transfer: "",
          utilityBillPayment: "",
        },
      },
      token: null,
      expiresAt: null,
      preferredAsset: "",
      enabled2fa: false,
      businessFeatures: [],
    },

  getters: {
    isAuthenticated: (state): boolean => {
      const { cookies } = useCookies();
      const token = cookies.get("token");
      return !!token;
    },
    getUser: (state) => {
      return state?.user;
    },
  },

  actions: {
    async login(form: any) {
      return await AuthService.login(form).then(
        (response: IAxiosApiResponse<ILoginResponse>) => {
          const { session, businessFeatures } = response.data.data;
          this.businessFeatures = businessFeatures || [];
          this.persistToCookie(session.token, session.expiresAt);
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },

    loginWithPasskey(response: any) {
      const { session, businessFeatures } = response.data.data;
      this.businessFeatures = businessFeatures || [];
      this.persistToCookie(session.token, session.expiresAt);
    },

    checkUser(form: { identifier: string }) {
      return AuthService.checkUser(form).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },

    signUp(form: ISignUpForm) {
      return AuthService.signUp(form).then(
        (response: IAxiosApiResponse<ILoginResponse>) => {
          const { session, businessFeatures } = response.data.data;
          this.businessFeatures = businessFeatures || [];
          this.persistToCookie(session.token, session.expiresAt);
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },

    sendValidationToken(data: IResetTokenForm, type: CHANNELS) {
      return AuthService.sendValidationToken(data, type).then(
        (response) => {
          return Promise.resolve(response?.data);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },

    fetchFromCookie() {
      const { cookies } = useCookies();
      let token = cookies.get("token");
      let expiresAt = parseInt(cookies.get("expiresAt"));
      return {
        token: token === "null" ? null : token,
        expiresAt,
      };
    },

    persistToCookie(token: string, expiresAt: string) {
      const { cookies } = useCookies();
      cookies.set("token", token);
      cookies.set("expiresAt", expiresAt);
    },

    async setUserDetails(response: IAxiosApiResponse) {
      this.user = response?.data?.data?.user ?? response?.data?.data?.profile;
      this.enabled2fa =
        response.data?.data?.securityStatus?.isGoogle2FaActive ?? false;
      this.preferredAsset = response.data?.data?.preferredAsset;
      this.limits = objectsToCamelCaseObjects(response.data?.data?.limits);
    },

    changeUserPreferredAssetCurrency: async function(data: {
      asset: IWallet["asset"];
    }): Promise<AxiosResponse> {
      return AuthService.changeUserPreferredAssetCurrency(data)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    setPreferredAsset(asset: string) {
      this.preferredAsset = asset;
    },

    clearUserDetails() {
      useUserStore().$reset();
      clearCookie();
    },

    async getResetToken(data: IResetTokenForm, type: string) {
      return AuthService.sendPasswordResetToken(data, type)
        .then((res: IAxiosApiResponse) => {
          return Promise.resolve(res.data);
        })
        .catch((error: AxiosError) => {
          return Promise.reject(error.response);
        });
    },

    async resetPassword(data: IResetPasswordForm) {
      return AuthService.resetPassword(data)
        .then((res) => {
          return res;
        })
        .catch((res) => {
          return {
            status: res.response.data.status,
            data: {
              message: res.response.data.message,
              error: res.response.data.message,
            },
          };
        });
    },

    async verifySSO(data: object) {
      return AuthService.verifySSO(data).then(
        (response) => {
          return Promise.resolve(response.data.data);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },

    async registerSocials(data: object) {
      return AuthService.registerSocials(data).then(
        (response: IAxiosApiResponse<ILoginResponse>) => {
          const { session, businessFeatures } = response.data.data;
          this.businessFeatures = businessFeatures || [];
          this.persistToCookie(session.token, session.expiresAt);
          setTimeout(() => Promise.resolve(response.data), 500);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },

    async loginSocials(data: object) {
      return AuthService.loginSocials(data).then(
        (response: IAxiosApiResponse<ILoginResponse>) => {
          const { session, businessFeatures } = response.data.data;
          this.businessFeatures = businessFeatures || [];
          this.persistToCookie(session.token, session.expiresAt);
          setTimeout(() => Promise.resolve(response.data), 500);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },
  },

  persist: true,
});
