const CAMPAIGN_SOURCE_KEY = "_c";

export function useCampaigns() {
  const getCampaignSource = () => {
    return localStorage.getItem(CAMPAIGN_SOURCE_KEY);
  };

  const setCampaignSource = (source: string) => {
    localStorage.setItem(CAMPAIGN_SOURCE_KEY, source);
  };

  const clearCampaignSource = () => {
    localStorage.removeItem(CAMPAIGN_SOURCE_KEY);
  };

  return {
    getCampaignSource,
    setCampaignSource,
    clearCampaignSource,
  };
}
