import { createToaster } from "@meforma/vue-toaster";

export interface IToastOptions {
  position?: string;
  duration?: number;
  pauseOnHover?: boolean;
  useDefaultCss?: boolean;
  queue?: boolean;
  maxToasts?: number;
}

const toaster = createToaster({
  position: "top",
  duration: 3000,
  pauseOnHover: true,
  useDefaultCss: false,
  queue: false,
  maxToasts: 2,
});

enum toastType {
  success = "success",
  error = "error",
  warning = "warning",
  info = "info",
}

interface iToastColors {
  [key: string]: {
    background: string;
    text: string;
    fill: string;
  };
}

const toastColors: iToastColors = {
  success: {
    background: "bg-[#F4FCF7]",
    text: "success",
    fill: "fill-success",
  },
  error: {
    background: "bg-[#FFF6F6]",
    text: "danger",
    fill: "fill-danger",
  },
  warning: {
    background: "bg-[#FFF4E7]",
    text: "primary-1",
    fill: "fill-primary-1",
  },
  info: {
    background: "bg-[#f2fbff] dark:bg-dark",
    text: "sky-500",
    fill: "fill-dark dark:fill-white",
  },
};

const toastTemplate = (message: string, type: toastType) => {
  return `
    <div class="flex h-full rounded-lg px-4 py-3 w-full gap-6 items-center justify-between ${toastColors[type].background}">
      <p class="text-sm text-${toastColors[type].text}">${message}</p>
      <button type="button" class="close-toast">
      <svg class="${toastColors[type].fill} w-3 h-3" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"/>
      </svg>
      </button>
    </div>`;
};

interface iToast {
  success: (message: string, options?: IToastOptions) => void;
  error: (message: string, options?: IToastOptions) => void;
  info: (message: string, options?: IToastOptions) => void;
  warning: (message: string, options?: IToastOptions) => void;
}

export const toast: iToast = {
  error: (message: string, options?: IToastOptions) => {
    toaster.show(toastTemplate(message, toastType.error), options);
  },
  success: (message: string, options?: IToastOptions) => {
    toaster.show(toastTemplate(message, toastType.success), options);
  },
  info: (message: string, options?: IToastOptions) => {
    toaster.show(toastTemplate(message, toastType.info), options);
  },
  warning: (message: string, options?: IToastOptions) => {
    toaster.show(toastTemplate(message, toastType.warning), options);
  },
};
