interface iErrorMessages {
  server: {
    [key: number | string]: string;
  };
}

export const defaultErrorMessage =
  "An Error Occurred. Please try again or please contact support";

export const errorMessages: iErrorMessages = {
  server: {
    500: defaultErrorMessage,
    401: "Session expired. please login again",
    502: defaultErrorMessage,
    OFF: "Network Error. Please check your connection",
    DEFAULT: defaultErrorMessage,
  },
};
