import { initializeApp } from "firebase/app";
import { collection, getDocs, getFirestore } from "firebase/firestore";

type CampaignImages = {
  id: string;
  image: string;
  url: string;
};

const API_KEY = import.meta.env.VITE_APP_FIREBASE_API_KEY;
const AUTH_DOMAIN = import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN;
const DATABASE_URL = import.meta.env.VITE_APP_FIREBASE_DATABASE_URL;
const PROJECT_ID = import.meta.env.VITE_APP_FIREBASE_PROJECT_ID;
const STORAGE_BUCKET = import.meta.env.VITE_APP_FIREBASE_STORAGE_BUCKET;
const MESSAGING_SENDER_ID = import.meta.env
  .VITE_APP_FIREBASE_MESSAGING_SENDER_ID;
const APP_ID = import.meta.env.VITE_APP_FIREBASE_APP_ID;
const MEASUREMENT_ID = import.meta.env.VITE_APP_GTAG_MEASUREMENT_ID;

export const firebaseApp = initializeApp({
  // your application settings
  apiKey: API_KEY,
  authDomain: AUTH_DOMAIN,
  databaseURL: DATABASE_URL,
  projectId: PROJECT_ID,
  storageBucket: STORAGE_BUCKET,
  messagingSenderId: MESSAGING_SENDER_ID,
  appId: APP_ID,
  measurementId: MEASUREMENT_ID,
});

// used for the firestore refs
const db = getFirestore(firebaseApp);

export const getImages = async () => {
  const imagesCollection = collection(db, "images");
  const imagesSnapshot = await getDocs(imagesCollection);

  const images: CampaignImages[] = [];

  imagesSnapshot.forEach((doc) => {
    images.push(doc.data() as CampaignImages);
  });

  return images;
};

export const getPortraitImages = async () => {
  const imagesCollection = collection(db, "portrait-images");
  const imagesSnapshot = await getDocs(imagesCollection);

  const images: CampaignImages[] = [];

  imagesSnapshot.forEach((doc) => {
    images.push(doc.data() as CampaignImages);
  });

  return images;
};
