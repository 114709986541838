import axios from "axios";

const noAuthaxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_APP_BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-Medium": "web",
  },
});
export default noAuthaxiosInstance;
