import { MIDDLEWARES, ROUTES } from "@/utils/consts";

export const tradeRoutes = [
  {
    path: ROUTES.TRADE.path,
    name: ROUTES.TRADE.path,
    component: () => import("@/pages/trade-crypto/index.vue"),
    meta: {
      middlewares: { [MIDDLEWARES.PROTECTED]: true },
      displayName: ROUTES.TRADE.displayName,
    },
    children: [
      // wallet deposit route
      {
        path: "deposit/",
        component: () => import("@/pages/wallet/actions/index.vue"),
        props: {
          backPath: ROUTES.TRADE.path,
        },
        children: [
          {
            path: "",
            component: () =>
              import("@/pages/wallet/actions/select-category.vue"),
            props: {
              action: "deposit",
              parentRoute: ROUTES.TRADE.path,
            },
          },
          {
            path: ":category",
            component: () =>
              import(
                "@/pages/wallet/actions/deposit/select-wallet-wrapper.vue"
              ),
            props: {
              backPath: `${ROUTES.TRADE.path}/deposit/`,
            },
          },
          {
            path: ":category/:asset",
            component: () =>
              import("@/pages/wallet/actions/deposit/deposit-wrapper.vue"),
            props: (route: any) => ({
              backPath: `${ROUTES.TRADE.path}/deposit/${route.params.category}`,
            }),
          },
        ],
      },
      {
        path: "withdraw/",
        component: () => import("@/pages/wallet/actions/index.vue"),
        props: {
          backPath: "/trade",
        },
        children: [
          {
            path: "",
            component: () =>
              import("@/pages/wallet/actions/select-category.vue"),
            props: {
              action: "withdraw",
              parentRoute: ROUTES.TRADE.path,
            },
          },
          {
            path: ":category",
            component: () =>
              import(
                "@/pages/wallet/actions/withdraw/select-wallet-wrapper.vue"
              ),
          },
          // {
          //   path: ":category/:asset",
          //   component: () => import("@/pages/wallet/actions/withdraw/withdraw-wrapper.vue"),
          //   props: {
          //     backPath: "/trade",
          //   },
          // },
          {
            path: "transfer/:category",
            component: () =>
              import(
                "@/pages/wallet/actions/withdraw/select-wallet-wrapper.vue"
              ),
          },
          {
            path: "transfer/:category/:asset",
            component: () =>
              import(
                "@/pages/wallet/actions/withdraw/fiat/transfers/index.vue"
              ),
            props: {
              backPath: "/wallets",
            },
          },
          // {
          //   path: ":category(crypto|fiat)/:asset",
          //   component: () =>
          //     import("@/pages/wallet/actions/withdraw/withdraw-wrapper.vue"),
          //   props: {
          //     backPath: "/trade",
          //   },
          // },
        ],
      },
      {
        path: "swap/:asset?",
        component: () => import("@/pages/wallet/actions/index.vue"),
        children: [
          {
            path: "",
            component: () =>
              import("@/pages/wallet/actions/trade/trade-wrapper.vue"),
          },
        ],
      },
    ],
  },
];
