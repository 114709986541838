import axios from "axios";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  "X-Medium": "web",
};

const createAxiosInstance = (baseURL: string) => {
  return axios.create({
    baseURL,
    timeout: 1000 * 60 * 5,
    headers,
  });
};

const createGuestAxiosInstance = (
  baseURL: string,
  optionalHeaders: Record<string, string>
) => {
  return axios.create({
    baseURL,
    timeout: 1000 * 60 * 5,
    headers: {
      ...headers,
      ...optionalHeaders,
    },
  });
};

const axiosInstance = createAxiosInstance(
  import.meta.env.VITE_APP_BASE_API_URL
);

export type CustomErrorResponse = {
  message: string;
  errors?: { [key: string]: string[] | string };
};

export { axiosInstance, createGuestAxiosInstance };
