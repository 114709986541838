if (navigator.serviceWorker) {
  navigator.serviceWorker.register(
    import.meta.env.MODE === 'production' ? '/sw.js' : '/dev-sw.js?dev-sw').then(function(registration) {
    console.log(registration);
    console.log('ServiceWorker registration successful with scope:', registration.scope);
    if (registration.installing) {
      console.log("Service worker installing");
    } else if (registration.waiting) {
      console.log("Service worker --installed");
    } else if (registration.active) {
      console.log("Service worker active");
    }
  }).catch(function(error) {
    console.log('ServiceWorker registration failed:', error);
  });
}
