import { axiosInstance } from "@/api";
import { setupInterceptor } from "./interceptor";

const defaultInterceptor = () => setupInterceptor(axiosInstance);

function setupInterceptors() {
  defaultInterceptor();
}

export { setupInterceptors };
