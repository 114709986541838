import { createRouter, createWebHistory } from "vue-router";
import SETUP_ROUTE_GAURD from "@/middleware/route-guard";
import new_routes from "@/router/routes";
// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.

const setupRouter = async () => {
  const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes: new_routes, // await Promise.all(routes)
    scrollBehavior(to, from, savedPosition) {
      if (!to.meta.keepPosition && !from.meta.keepPosition) {
        if (savedPosition) {
          return { top: 0 };
          // return savedPosition
        } else {
          return { top: 0 };
        }
      }
      return;
    },
  });

  SETUP_ROUTE_GAURD(router);

  return router;
};


export const router = setupRouter();


